import './App.css';
import * as React from 'react';
import Header from './components/Header';
import Configuration from './components/Configuration';
import Simulator from './components/Simulator';
import Export from './components/Export';
import Browser from './components/Browser';
import { Alert, Snackbar } from '@mui/material';
import { on, off } from "./events";

function App() {

  const [configuration, setConfiguration] = React.useState(null)



  const [snakOpen, setSnakOpen] = React.useState(false)
  const [snakType, setSnakType] = React.useState('info')
  const [snakMessage, setSnakMessage] = React.useState('')

  const [view, setView] = React.useState('configuration')


  const handleSnakClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnakMessage('')
    setSnakOpen(false);
  };


  const handleSnak = (e) => {
    setSnakType(e.detail.type)
    setSnakMessage(e.detail.message)
    setSnakOpen(true)
  }


  React.useEffect(() => {
    on("snak", handleSnak);

    return () => {
      off("snak", handleSnak);
    }
  }, [handleSnak]);


  return (
    <div className="App">
      <Header view={view} onChangeView={setView} configuration={configuration} />
      <Simulator isVisible={view === 'simulator'} configuration={configuration} />
      <Browser isVisible={view === 'browser'} configuration={configuration} />
      <Configuration isVisible={view === 'configuration'} configuration={configuration} setConfiguration={setConfiguration} />
      <Export isVisible={view === 'export'} configuration={configuration} />
      <Snackbar
        open={snakOpen}
        autoHideDuration={6000}
        onClose={handleSnakClose}
      >
        <Alert onClose={handleSnakClose} severity={snakType} sx={{ width: '100%' }}>
          {snakMessage}
        </Alert>
      </Snackbar>

    </div>
  );
}

export default App;
