import * as React from 'react';
import { trigger } from '../events';
import { calculateLevel, evaluateClassifications, handlePoints, MissingGeneratorParameterError } from '../utils';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { Button, FormGroup, Modal, Typography } from '@mui/material';
import { saveAs } from 'file-saver';
import PointMatrix from './PointMatrix';
import ResultsTable from './ResultsTable';
import Survey from './Survey';
import {UpdatedConfigsContext} from '../UpdatedConfigsContext'


function Simulator(props) {


    const { configuration, isVisible } = props

    const [variableValues, setVariableValues] = React.useState({})
    const [showFormulas, setShowFormulas] = React.useState(false)
    const {selectedLang, setSelectedLang} = React.useContext(UpdatedConfigsContext)

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }




    const Input = styled('input')({
        display: 'none',
    });


    const saveSimulatorState = () => {
        if(!('version' in configuration.metadata[0])) {
            trigger('snak', {type: 'error', message: 'configuration is missing version field.'})
        }
        let data = {}
        data['data'] = variableValues
        data['metadata'] = {
            version: configuration.metadata[0].version
        }
        const filename = 'simulator-data-version-' + configuration.metadata[0].version + '.json'
        saveAs(new Blob([JSON.stringify(data)], { type: "application/octet-stream" }), filename)
    }

    const resetSimulator = () => {
        setVariableValues({})

    }
    const onInput = (q, event) => {
        const qtype = q.type
        let r = { ...variableValues }

        if (qtype === 'QT' || qtype === 'QI') {
            r = handleTextAnswer(q, event.target.value, r)
        }
        else if (q.type == 'QSO' || q.type == 'QS' || q.type == 'QM') {
            r = handleCodeQuestionAnswer(q, event, r)
        }
        else {
            trigger('snak', { type: 'error', message: "Could not handle simulator input. Unknown question type " + qtype })
        }
        if(q.id in configuration.dependantVariables) {
            r = handleDependantVariables(r, configuration.dependantVariables[q.id])
        }
        
        setVariableValues(r)
        
    }

    const handleDependantVariables = (variableValues, dependantVariables) => {
        dependantVariables.forEach( qid => {
            let q = configuration.questions[qid]
            let value = variableValues[q.id]
            variableValues = handleCodeQuestionAnswer(q, value, variableValues)
        })
        return variableValues

    }

    const handleTextAnswer = (q, value, r) => {
        let pp = 0
        let lp = 0
        if (value.trim() != '') {
            pp = q.pp
            lp = q.lp
        }
        r[q.id] = value
        r[q.id + '_pp'] = pp
        r[q.id + '_lp'] = lp
        return r
    }
    const handleCodeQuestionAnswer = (q, value, r) => {
        let pp = 0
        let lp = 0
        if (value === null) {
            delete r[q.id]
        }
        else {
            r[q.id] = value
            if (!Array.isArray(value)) {
                value = [value]
            }
            q.values.forEach(c => {
                if (value.indexOf(c.code) >= 0) {

                    pp = pp + handlePoints(r, c, c.pp)
                    lp = lp + handlePoints(r, c, c.lp)
                }
            })
            r[q.id + '_pp'] = pp
            r[q.id + '_lp'] = lp
        }
        return r
    }


    const loadSimulatorState = (e) => {
        let file = e.target.files[0]
        if (file) {
            var reader = new FileReader();
            reader.onload = function (event) {
                try {
                    let error = ''
                    const fileData = JSON.parse(event.target.result)
                    let variableValues = null
                    if('metadata' in fileData) {
                        let m = fileData.metadata
                        if('version' in m && m.version === configuration.metadata[0].version) {
                            variableValues = fileData.data
                        }
                        else {
                            error = 'Version mismatch. Loaded configuration version is ' + configuration.metadata[0].version + ' and file was create with version ' + m.version
                        }
                    }   
                    else {
                        error = 'missing metadata key in simulator data file.'
                    }
                    
                    if (error != '') {
                        trigger('snak', { type: 'error', message: error })
                    }
                    else {
                        setVariableValues(variableValues)
                    }

                } catch (error) {
                    trigger('snak', { type: 'error', message: "Could not parse simulator data JSON" })
                }

            };
            reader.readAsText(file);
        }
    }



    if (isVisible) {
        const { levelGenerator, questions, sequences, hierarchy, classifications, metadata } = configuration
        let missing = []
        let c = {}
        let level = ''
        try { 
                
            const orgType = variableValues[metadata[0].excludeVariable] || null
            c = evaluateClassifications(classifications, questions, variableValues, metadata[0].excludeVariable)
            level = calculateLevel(c, levelGenerator, orgType)
        } catch (e) {
            if (e instanceof MissingGeneratorParameterError) {
                missing = e.missing
            }
        }
        return (

            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Survey readOnly={false} hierarchy={hierarchy} sequences={sequences} variableValues={variableValues} onInput={onInput} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '550px', position: 'sticky', padding: '10px' }}>
                    <Modal
                        open={showFormulas}
                        onClose={(e) => { setShowFormulas(false) }}

                    >
                        <Box sx={modalStyle}>
                            <Typography variant="h6" component="h2">
                                Degree
                            </Typography>
                            <Typography sx={{ mt: 2, whiteSpace: 'pre' }}>
                                {
                                    classifications.length > 0 &&
                                    (
                                        <span>{classifications[0].generatorStr}</span>
                                    )
                                }

                            </Typography>
                            <Typography sx={{ mt: 2 }} variant="h6" component="h2">
                                Level
                            </Typography>
                            <Typography sx={{ mt: 2, whiteSpace: 'pre' }}>
                                {levelGenerator}
                            </Typography>

                        </Box>
                    </Modal>
                    <Box>
                        <Button variant="contained" sx={{ m: 1 }} onClick={resetSimulator}>
                            Reset
                        </Button>
                        <label htmlFor="contained-button-simulator-file">
                            <Input onChange={loadSimulatorState} accept="application/json" id="contained-button-simulator-file" type="file" />
                            <Button sx={{ m: 1 }} variant="contained" component="span">
                                Load
                            </Button>
                        </label>
                        <Button variant="contained" sx={{ m: 1 }} onClick={saveSimulatorState}>Save</Button>
                    </Box>
                    {
                        missing.length > 0 &&
                        <Box sx={{ minWidth: '550px', position: 'sticky', padding: '10px' }}>
                            <Box sx={{ m: 3 }}>
                                Tulosten laskemiseksi vaaditaan vastaukset seuraaviin kysymyksiin:
                                {
                                    missing.map((m , m_index) => {
                                        if (m in questions) {
                                            return (<Typography key={m_index}>{questions[m].text2[selectedLang]}</Typography>)
                                        }
                                        else {
                                            return (<Typography key={m_index}>{m}</Typography>)
                                        }
                                    })
                                }

                            </Box>
                        </Box>
                    }
                    {
                        missing.length == 0 &&
                        <>
                            <PointMatrix variableValues={variableValues} isVisible={true} />
                            <ResultsTable c={c} level={level} isVisible={missing.length == 0 ? true : false} />
                            <FormGroup>
                                <Button onClick={(e) => { setShowFormulas(true) }}>Näytä laskukaavat</Button>
                            </FormGroup>

                        </>

                    }

                </Box>


            </Box>
        )
    }
    else {

        return <Typography></Typography>
    }
}
export default Simulator