import { AppBar, Button, MenuItem, Select, Toolbar, Typography } from '@mui/material';
import * as React from 'react';
import {UpdatedConfigsContext} from '../UpdatedConfigsContext'


function Header(props) {
    const {configuration, view, onChangeView} = props
    const configurationLoaded = configuration != null
    const {selectedLang, setSelectedLang} = React.useContext(UpdatedConfigsContext)

    const handleLangChange = (e) => {
      setSelectedLang(e.target.value)
    }
    return (
        <AppBar position="static">
          <Toolbar>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
              >
                Questionnaire toolkit v0.9.3
              </Typography>
              <Button onClick={ (e) => onChangeView('configuration')} sx={{fontSize: '80%'}} variant={view == 'configuration' ? 'outlined': ''} color="inherit">Configuration</Button>
              <Button disabled={!configurationLoaded} onClick={ (e) => onChangeView('simulator')} sx={{fontSize: '80%'}} variant={view == 'simulator' ? 'outlined': ''} color="inherit">Simulator</Button>
              <Button disabled={!configurationLoaded} onClick={ (e) => onChangeView('export')} sx={{fontSize: '80%'}} variant={view == 'export' ? 'outlined': ''} color="inherit">Export</Button>
              <Button disabled={!configurationLoaded} onClick={ (e) => onChangeView('browser')} sx={{fontSize: '80%'}} variant={view == 'browser' ? 'outlined': ''} color="inherit">Result browser</Button>              
              <Button>
              {
                configuration && (
                  <Select 
                    value={selectedLang}
                    onChange={handleLangChange}
                  >
                    { configuration && (
                      configuration.metadata[0].languages.map( (l, lindex) => {
                        return <MenuItem key={l} value={lindex}>{l}</MenuItem>
                      })
                    )
  
                    }
                </Select>
  
                )
              }
              </Button>
            </Toolbar>
        </AppBar>        
    )
}
export default Header;