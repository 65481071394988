import { Button, Divider, FormControl, InputLabel, LinearProgress, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { trigger } from '../events';
import { parseExcel } from '../utils';
import XLSX from 'xlsx';

function Configuration(props) {
    const {configuration, setConfiguration, isVisible} = props 

    const [isLoading, setIsLoading] = React.useState(false)
    const configurationInput = React.useRef()
    const configurationLoaded = configuration != null ? true : false
    const [selectedConfiguration, setSelectedConfiguration] = React.useState('')

    const sheet2022draft = 'https://docs.google.com/spreadsheets/d/1ctdjOfzbb0VjfQCnRJelge7AVRbyyfMXcDbXvVTbN4w/export?format=xlsx'
    const sheetFull = 'https://docs.google.com/spreadsheets/d/19-p-kdK9gZcFZH7iKAiBoyL-zFpllTocUvr9EH1c-oM/export?format=xlsx'

    const loadData = (url) => {
        setIsLoading(true)
        var req = new XMLHttpRequest();
        req.open("GET", url, true);
        req.responseType = "arraybuffer";    
        req.onload = function(e) {
          var data = new Uint8Array(req.response);
          var workbook = XLSX.read(data, {type:"array"});
          parseExcel(workbook)
          .then((data) => {
            setIsLoading(false)
            setConfiguration(data)
            trigger('resetSimulator')
          })
          .catch( error => {
            console.error(error)
            trigger('snak', {type:'error', message:error})
          })
        }
        req.onerror = function (error) {
          setIsLoading(false)
          trigger('snak', {type:'error', message:'Could not load configuration. Check the URL'})
        };    
        req.send();       
        
    }    

    const handleLoadUrl = () => {
      const url = configurationInput.current.value
      loadData(url)
    }
    const handleSurveyChange = (e) => {
      const url = e.target.value
      setSelectedConfiguration(e.target.value)
      loadData(url)
    }

    const clear = () => {
      setConfiguration(null)
      setSelectedConfiguration('')      
    } 

    if(isVisible) {

    return (
        <Box sx={{display:'flex', flexDirection:'column', alignItems:'flex-start', m:3, rowGap:2, textAlign:'left'}}>
            <Typography>
              Configuration source
            </Typography>
            <TextField 
              disabled={isLoading || configurationLoaded} 
              
              defaultValue='' 
              id="standard-basic" label="Sheet data url" inputRef={configurationInput} sx={{width:'500px'}}>                
            </TextField>
            {
            !configurationLoaded && (
                <Button disabled={isLoading} onClick={handleLoadUrl} variant="contained">Load configuration</Button>
            )
            }
            <Divider>OR</Divider>

              <FormControl fullWidth>
                <InputLabel id="tsvlist">Select survey</InputLabel>
                <Select
                  labelId="dtsvlist"
                  id="tsvlist-select"
                  value={selectedConfiguration}
                  label="Select survey"
                  onChange={handleSurveyChange}
                  disabled={configurationLoaded}
                >
                  <MenuItem value={sheet2022draft}>Avoimen tieteen ja tutkimuksen seurannassa käytettävä kysely 2022</MenuItem>
                </Select>
              </FormControl>              
        { isLoading && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
        )}

        {
          configurationLoaded && (
            <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>Configuration loaded</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {
                  Object.keys(configuration.metadata[0]).map( mkey => {
                    return (
                      <TableRow key={mkey}>
                        <TableCell sx={{fontWeight: 'bold'}}>{mkey}</TableCell>                              
                        <TableCell>{configuration.metadata[0][mkey]}</TableCell>                              
                      </TableRow>
                    )
                  })
                }
                </TableBody>
              </Table>
            </TableContainer>
            <Button onClick={clear} variant="contained">Clear configuration</Button>
            </>
          )
        }
        
      </Box>        
    )
    }
    else {
        return <div></div>
    }
}
export default Configuration