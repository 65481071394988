import * as React from 'react';
import { Card, CardContent, Typography, ToggleButtonGroup, ToggleButton, TextField, Box } from '@mui/material';

import {UpdatedConfigsContext} from '../UpdatedConfigsContext'

function SurveySection(props) {
    const {selectedLang, setSelectedLang} = React.useContext(UpdatedConfigsContext)
 
    const {q, qindex, variableValues, onInput, readOnly, ...other} = props
  
    if(q.type === 'P') {
      return <Typography>{q.text2[selectedLang]}</Typography>
    }    
    return (
      <Card>
        <CardContent>
          <Typography variant="body">
          {q.id}. {q.text2[selectedLang]}
          </Typography>
          
          {
            
            (q.type == 'QSO' || q.type == 'QS' || q.type == 'QM') && (
              <ToggleButtonGroup
                disabled={readOnly}
                sx={{width:'100%'}}
                value={variableValues[q.id]}
                exclusive={q.type.startsWith('QS') ? true:  false}
                orientation="vertical"
                onChange={(e, v) => onInput(q, v) }
              >
                {
                  q.values.map( (v, v_index) => {
                    return (
                    <ToggleButton key={v_index} value={v.code}>
                      {
                        (!readOnly && v.link && v.link != '') && (
                          <a target="_blank" href={v.link}>{v.text2[selectedLang]}</a>
                        )                        
                      }
                      {
                        (readOnly || !v.link || v.link == '') && (
                          <span>{v.text2[selectedLang]}</span>
                        )
                      }
                      
                    </ToggleButton>
                    )
                  })
                }
  
            </ToggleButtonGroup>
            )
          }
          {
            (q.type === 'QT' || q.type === 'QI') && (
              <TextField sx={{width:'100%'}} value={variableValues[q.id]} onChange={(e) => onInput(q, e) }  />
            )
          } 
          { 
            q.type != 'P' && (
              <Box sx={{p:1}}>
                pp: {variableValues[q.id + '_pp']}, lp: {variableValues[q.id + '_lp']}            
              </Box>
            )
            
          
          }
          
  
        </CardContent>
      </Card>
    )
  
  }
  export default SurveySection