import {createContext, useState} from 'react'


const UpdatedConfigsContext = createContext()

const UpdatedConfigsContextProvider = ({children} ) => {
    const [selectedLang, setSelectedLang] = useState(0)

    return (<UpdatedConfigsContext.Provider value={{ selectedLang, setSelectedLang}}>
        {children}
    </UpdatedConfigsContext.Provider>
    )
}

export {UpdatedConfigsContext, UpdatedConfigsContextProvider}