import * as React from 'react';
import { FormControlLabel, FormGroup, Switch, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { calculatePointMatrix, getPointMatrixSum } from '../utils';

function PointMatrix(props) {

    const rows = 4
    const cols = 5
    const ROW_TO_LABEL = ['Toiminta- kulttuuri', 'Julkaisut', 'Aineisto ja infrastruktuuri', 'Oppiminen']
    const bStyle = { fontWeight: 'bold' }

    const { isVisible, variableValues } = props

    const [sumMatrixValues, setSumMatrixValues] = React.useState(true)

    


    if (isVisible) {
        let colSum = [[0, 0, 0, 0], [0, 0, 0, 0]]
        let rowSum = [0, 0]        

        const pointMatrix = calculatePointMatrix(rows, cols, variableValues)
        return (
            <>
                <TableContainer>
                    <Table padding={'none'} sx={{}} size="small" aria-label="a dense table">
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row"></TableCell>
                                <TableCell component="th" scope="row">Ohjaavat dokumentit</TableCell>
                                <TableCell component="th" scope="row">Palvelut</TableCell>
                                <TableCell component="th" scope="row">Yhteistyö</TableCell>
                                <TableCell component="th" scope="row">Tuotokset</TableCell>
                                <TableCell component="th" sx={{ minWidth: '35px' }} scope="row"></TableCell>
                            </TableRow>
                            {
                                [0, 1, 2, 3].map((row) => {
                                    rowSum = [0, 0]

                                    return (
                                        <TableRow>
                                            <TableCell component="th" scope="row">{ROW_TO_LABEL[row]}</TableCell>
                                            {
                                                [0, 1, 2, 3].map((col) => {
                                                    let sum = getPointMatrixSum(pointMatrix, row, col)
                                                    rowSum[0] = rowSum[0] + sum[0]
                                                    rowSum[1] = rowSum[1] + sum[1]
                                                    colSum[0][col] = colSum[0][col] + sum[0]
                                                    colSum[1][col] = colSum[1][col] + sum[1]
                                                    if (sumMatrixValues) {
                                                        return <TableCell component="th" scope="row">{sum[0] + sum[1]}</TableCell>
                                                    }
                                                    else {
                                                        return <TableCell component="th" scope="row">{sum[0]},  {sum[1]}</TableCell>
                                                    }


                                                })

                                            }
                                            {sumMatrixValues &&
                                                <TableCell component="th" sx={bStyle} scope="row">{rowSum[0] + rowSum[1]}</TableCell>
                                            }
                                            {!sumMatrixValues &&
                                                <TableCell component="th" sx={bStyle} scope="row">{rowSum[0]},  {rowSum[1]}</TableCell>
                                            }

                                        </TableRow>
                                    )
                                })
                            }
                            <TableRow>
                                <TableCell component="th" scope="row"></TableCell>
                                {sumMatrixValues &&
                                    <TableCell component="th" sx={bStyle} scope="row">{colSum[0][0] + colSum[1][0]}</TableCell>
                                }
                                {!sumMatrixValues &&
                                    <TableCell component="th" sx={bStyle} scope="row">{colSum[0][0]},  {colSum[1][0]}</TableCell>
                                }
                                {sumMatrixValues &&
                                    <TableCell component="th" sx={bStyle} scope="row">{colSum[0][1] + colSum[1][1]}</TableCell>
                                }
                                {!sumMatrixValues &&
                                    <TableCell component="th" sx={bStyle} scope="row">{colSum[0][1]},  {colSum[1][1]}</TableCell>
                                }
                                {sumMatrixValues &&
                                    <TableCell component="th" sx={bStyle} scope="row">{colSum[0][2] + colSum[1][2]}</TableCell>
                                }
                                {!sumMatrixValues &&
                                    <TableCell component="th" sx={bStyle} scope="row">{colSum[0][2]},  {colSum[1][2]}</TableCell>
                                }
                                {sumMatrixValues &&
                                    <TableCell component="th" sx={bStyle} scope="row">{colSum[0][3] + colSum[1][3]}</TableCell>
                                }
                                {!sumMatrixValues &&
                                    <TableCell component="th" sx={bStyle} scope="row">{colSum[0][3]},  {colSum[1][3]}</TableCell>
                                }

                                {sumMatrixValues &&
                                    <TableCell component="th" sx={bStyle} scope="row">{(colSum[0].reduce((a, b) => a + b, 0)) + (colSum[1].reduce((a, b) => a + b, 0))}</TableCell>
                                }
                                {!sumMatrixValues &&
                                    <TableCell component="th" sx={bStyle} scope="row">{colSum[0].reduce((a, b) => a + b, 0)},  {colSum[1].reduce((a, b) => a + b, 0)}</TableCell>
                                }

                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <FormGroup>
                    <FormControlLabel control={<Switch checked={sumMatrixValues} onChange={e => setSumMatrixValues(e.target.checked)} />} label="Summaa pp ja lp" />
                </FormGroup>
            </>
        )
    }
    else {
        return <div></div>
    }
}
export default PointMatrix