import { Box } from '@mui/system';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Accordion, AccordionDetails, AccordionSummary, Button, LinearProgress, Typography } from '@mui/material';
import PointMatrix from './PointMatrix';
import ResultsTable from './ResultsTable';
import { calculateLevel, calculatePointMatrix, evaluateClassifications, MissingGeneratorParameterError, parseSurveyResults } from '../utils';
import { trigger } from '../events';
import Survey from './Survey';

const Input = styled('input')({
    display: 'none',
});

function Browser(props) {
    const { configuration, isVisible } = props

    const [isLoading, setIsLoading] = React.useState(false)
    const [selectedBrowseItem, setSelectedBrowseItem] = React.useState(null)
    const [browserResult, setBrowserResult] = React.useState([])

    const clearBrowserItems = () => {
        setBrowserResult([])
        setSelectedBrowseItem(null)

    }

    const removeBrowserItem = (index) => (event) => {
        let r = [...browserResult]

        r.splice(index, 1)
        if (r.length > 0) {
            if ((index - 1) >= 0) {
                setSelectedBrowseItem(index - 1)
            }
            else {
                setSelectedBrowseItem(0)
            }

        }
        else {
            setSelectedBrowseItem(null)
        }
        setBrowserResult(r)
    }

    const handleBrowserItemChange = (item) => (event, isExpanded) => {
        if (item != selectedBrowseItem) {
            setSelectedBrowseItem(isExpanded ? item : false);
        }
    }

    const handleSimulatorDataUpload = (e) => {
        let file = e.target.files[0]
        if (file) {
            var reader = new FileReader();
            reader.onload = function (event) {
                try {
                    const { levelGenerator, questions, classifications, metadata } = configuration
                    const fileData = JSON.parse(event.target.result)
                    let variableValues = null
                    let error = ''

                    if('metadata' in fileData) {
                        let m = fileData.metadata
                        if('version' in m && m.version === configuration.metadata[0].version) {
                            variableValues = fileData.data
                        }
                        else {
                            error = 'Version mismatch. Loaded configuration version is ' + configuration.metadata[0].version + ' and file was create with version ' + m.version
                        }
                    }   
                    else {
                        error = 'missing metadata key in simulator data file.'
                    }

                    if (error != '') {
                        trigger('snak', { type: 'error', message: error })
                    } 
                    else {
                        let pm = calculatePointMatrix(4, 5, variableValues)
                        let r = [...browserResult]
                        let label = 'none'
                        let labelVariable = metadata[0]['labelVariable']
                        if (labelVariable != null && labelVariable in variableValues) {
                            let q = questions[labelVariable]
                            if (q.type === 'QT' || q.type === 'QI') {
                                label = variableValues[labelVariable]
                            }
                            else if (q.type === 'QS' || q.type === 'QM') {
                                questions[labelVariable].values.map(v => {
                                    if (v.code == variableValues[labelVariable])
                                        label = v.text
                                })
    
                            }
                        }
                        const orgType = variableValues[metadata[0].excludeVariable] || null
                        let c = evaluateClassifications(classifications, questions, variableValues,  metadata[0].excludeVariable)
                        let level = calculateLevel(c, levelGenerator, orgType)
                        let newItemIndex = r.length
                        r.push({ label: label, data: variableValues, pointMatrix: pm, c: c, level: level })
                        setBrowserResult(r)
                        setSelectedBrowseItem(newItemIndex)
                    }                   


                } catch (error) {
                    console.error(error)
                    if(error instanceof MissingGeneratorParameterError) {
                        trigger('snak', { type: 'error', message: "Could not parse simulator data JSON. Missing variables values for result generation."})
                    }
                    else {
                        trigger('snak', { type: 'error', message: "Could not parse simulator data JSON." + error })
                    }
                    
                }

            };
            reader.readAsText(file);
        }
    }


    const handleLimeSurveyDataUpload = (e) => {
        setIsLoading(true)
        let file = e.target.files[0]
        if (file) {
            var reader = new FileReader();
            reader.onload = function (event) {
                try {
                    const { levelGenerator, questions, sequences, hierarchy, classifications, metadata } = configuration
                    let LMData = JSON.parse(event.target.result)
                    let responses = LMData['responses']
                    let items = parseSurveyResults(responses, hierarchy, sequences)
                    //console.log(items)
                    let r = [...browserResult]
                    items.forEach(variableValues => {

                        let label = 'none'
                        let labelVariable = metadata[0]['labelVariable']
                        if (labelVariable != null && labelVariable in variableValues) {
                            let q = questions[labelVariable]
                            if (q.type === 'QT' || q.type === 'QI') {
                                label = variableValues[labelVariable]
                            }
                            else if (q.type === 'QS' || q.type === 'QM') {
                                questions[labelVariable].values.map(v => {
                                    if (v.code == variableValues[labelVariable])
                                        label = v.text
                                })

                            }
                        }
                        const orgType = variableValues[metadata[0].excludeVariable] || null
                        let pm = calculatePointMatrix(4, 5, variableValues)
                        let c = evaluateClassifications(classifications, questions, variableValues, metadata[0].excludeVariable)
                        let level = calculateLevel(c, levelGenerator, orgType)

                        r.push({ label: label, data: variableValues, pointMatrix: pm, c: c, level: level })
                    })
                    setBrowserResult(r)
                    setSelectedBrowseItem(0)


                } catch (error) {
                    console.error(error)
                    trigger('snak', { type: 'error', message: "Could not parse survey result JSON. " + error })
                } finally {
                    setIsLoading(false)
                }

            };
            reader.readAsText(file);
        }
    }

    if (isVisible) {
        const { levelGenerator, questions, sequences, hierarchy, classifications, metadata } = configuration

        return (
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box sx={{ minWidth: window.innerWidth - 550, display: 'flex', flexDirection: 'column' }}>
                    {
                        selectedBrowseItem != null && (

                            <Survey readOnly={true} hierarchy={hierarchy} sequences={sequences} variableValues={browserResult[selectedBrowseItem].data} onInput={(() => { })} />
                        )
                    }
                    {
                        selectedBrowseItem == null && (
                            <Box sx={{ m: 3 }}>

                                <Typography>Select one of the options on the right to load data.</Typography>
                            </Box>
                        )
                    }

                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '550px', position: 'sticky', padding: '10px' }}>
                    <Box >
                        <label htmlFor="contained-button-simulator-file">
                            <Input onChange={handleSimulatorDataUpload} accept="application/json" id="contained-button-simulator-file" type="file" />
                            <Button sx={{ m: 1 }} variant="contained" component="span">
                                Simulator (file)
                            </Button>
                        </label>
                        <label htmlFor="contained-button-lm-file">
                            <Input onChange={handleLimeSurveyDataUpload} accept="application/json" id="contained-button-lm-file" type="file" />
                            <Button sx={{ m: 1 }} variant="contained" component="span">
                                LimeSurvey
                            </Button>
                        </label>
                        <Button onClick={clearBrowserItems}>Clear</Button>
                        {isLoading && (
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                        )}
                    </Box>
                    <Box sx={{ textAlign: 'left', maxHeight: window.innerHeight - 128, overflow: 'auto' }}>
                        {
                            browserResult.map((bdata, b_index) => {
                                return (
                                    <Accordion expanded={selectedBrowseItem === b_index} onChange={handleBrowserItemChange(b_index)}>
                                        <AccordionSummary>
                                            <Typography variant='h6'>
                                                {b_index + 1}. {browserResult[b_index].label}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <PointMatrix variableValues={browserResult[b_index].data} isVisible={true} />
                                            <ResultsTable c={browserResult[b_index].c} level={browserResult[b_index].level} isVisible={true} />
                                            <Button onClick={removeBrowserItem(b_index)}>Remove</Button>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })
                        }
                    </Box>
                </Box>


            </Box>
        )
    }
    else {
        return <div></div>
    }

}
export default Browser