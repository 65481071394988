import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import * as React from 'react';

function ResultsTable(props) {
    const {isVisible, c, level} = props
    const bStyle = {fontWeight: 'bold'}
     
    if(isVisible) {
      return (
        <>
        <TableContainer >
          <Table padding='none' sx={{  }} size="small" aria-label="a dense table">
            <TableBody>
              <TableRow>
                <TableCell variant='head' component="th" scope="row"></TableCell>
                <TableCell variant='head' component="th" scope="row">PP</TableCell>
                <TableCell variant='head' component="th" scope="row"></TableCell>
                <TableCell variant='head' component="th" scope="row">LP</TableCell>
                <TableCell variant='head' component="th" scope="row"></TableCell>
                <TableCell variant='head' component="th" scope="row">P</TableCell>
                <TableCell variant='head' component="th" scope="row"></TableCell>
                <TableCell variant='head' component="th" scope="row">Degree</TableCell>  
              </TableRow>
              {
                c.map( (row) => {
                  return (
                    <TableRow>
                      <TableCell component="th" scope="row">{row.group}</TableCell>
                      <TableCell component="th" scope="row">{row.max_pp > 0 ? row.pp + '/' + row.max_pp: '-'}</TableCell>
                      <TableCell component="th" scope="row">({row.max_pp > 0 ? ((row.pp/row.max_pp)*100).toFixed(1): '-'}%)</TableCell>
                      <TableCell component="th" scope="row">{row.max_lp > 0 ? row.lp + '/' + row.max_lp: '-'}</TableCell>
                      <TableCell component="th" scope="row">({row.max_lp > 0 ? ((row.lp/row.max_lp)*100).toFixed(1): '-'}%)</TableCell>
                      <TableCell component="th" scope="row">{row.max_p > 0 ? row.p + '/' + row.max_p : '-'}</TableCell>
                      <TableCell component="th" scope="row">({row.max_p > 0 ?((row.p/row.max_p)*100).toFixed(1): '-'}%)</TableCell>
                      <TableCell sx={bStyle} component="th" scope="row">{row.max_p > 0 ? row.degree: '-'}</TableCell>
                    </TableRow>
                 )
      
                })
              }
              <TableRow>
                <TableCell sx={bStyle} component="th" colspan={6}  scope="row"></TableCell>
                <TableCell sx={bStyle} component="th" scope="row">LEVEL</TableCell>
                <TableCell sx={bStyle} component="th" scope="row">{level}</TableCell>
              </TableRow>
      
            </TableBody>
          </Table>
        </TableContainer>
      
        </>
        )  
    }
    else {
      return <div></div>
    }
      
}
export default ResultsTable