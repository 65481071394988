import XLSX from 'xlsx';


function MissingGeneratorParameterError(missing) {
  this.missing = missing;
}

const initPointMatrix = (rows, cols) => {
  let m = []
  for (let i = 0; i < rows; i++) {

    m.push(new Array(cols).fill({}))
  }
  return m
}

const getPointMatrixSum = (pointMatrix, row, col) => {
  if (pointMatrix != null && pointMatrix.length > 0) {
    let d = pointMatrix[row][col]
    let sum = [0, 0]
    let keys = Object.keys(d)
    keys.forEach(k => {
      if (k.endsWith('_pp')) {
        sum[0] = sum[0] + d[k]
      }
      else {
        sum[1] = sum[1] + d[k]
      }
    })
    return sum

  }
  return 0
}

const calculatePointMatrix = (rows, cols, vv) => {
  const LETTER_TO_INDEX = ['A', 'B', 'C', 'D', 'E']
  let m = initPointMatrix(rows, cols)

  Object.keys(vv).map(variable => {
    if (!variable.endsWith('_pp') && !variable.endsWith('_lp')) {
      let pp = vv[variable + '_pp']
      let lp = vv[variable + '_lp']
      let parts = variable.split('.')
      if (parts.length == 3) {
        let col = LETTER_TO_INDEX.indexOf(parts[0])
        let row = parseInt(parts[1]) - 1
        if (col === 3) {
          row = 1
        }
        if (col === 4) {
          col = 3
          row = 3
        }

        let subindex = parts[2]
        let values = { ...m[row][col] }
        values[subindex + '_pp'] = pp
        values[subindex + '_lp'] = lp
        m[row][col] = values

      }

    }
  })
  return m
}

const flattenHierarchy = (r, h) => {
  h.forEach(_h => {
    if (_h.sheet && _h.sheet != '') {
      r.push(_h)
    }
    if (_h.children.length > 0) {
      flattenHierarchy(r, _h.children)
    }
  })
}

/**
 * Combines questionaire result data with survey configuration and returns both asserted and calculated variable values.
 * @param {Object[]} responses - data from LimeSurvey json export
 * @param {Object[]}} hierarchy - Sections sheet configuration organized in a hierarchy
 * @param {Object} sequences - Every sheet that describes a section. Sheet names as keys
 * @returns array of variable values objects for each survey answer.
 */
const parseSurveyResults = (responses, hierarchy, sequences) => {
  let items = []
  const groups = []
  let questions2 = []
  flattenHierarchy(groups, hierarchy)
  groups.forEach(g => {
    sequences[g.sheet].forEach(q => {
      if (q.type != 'P') {
        questions2.push(q)
      }

    })
  })
  responses.forEach(r => {
    let vv = {}
    let values = {}
    questions2.forEach(q => {
      let pp = 0
      let lp = 0
      let variableName = null
      let variableValue = null
      if (q.type === 'QM') {
        variableValue = []
        q.values.forEach(v => {
          variableName = q.id.replaceAll('.', 'z') + '[SQz' + v.code + ']'
          if (variableName in r && r[variableName] != '') {
            pp = pp + handlePoints(values, v, v.pp)
            lp = lp + handlePoints(values, v, v.lp)
            variableValue.push(v.code)
          }
        })
      }
      else if (q.type === 'QS' || q.type === 'QSO') {
        variableName = q.id.replaceAll('.', 'z')
        if (variableName in r && r[variableName] != '') {
          //let value = r[variableName].replaceAll('z', '.')
          let codeValue = parseInt(r[variableName].split('z')[1])
          // find correct C 
          let c = null
          q.values.map(_c => {
            if (_c.code === codeValue) {
              c = _c
            }
          })
          if (c) {
            pp = handlePoints(values, c, c.pp)
            lp = handlePoints(values, c, c.lp)
            variableValue = c.code
          }

        }
      }
      else if (q.type === 'QT') {
        variableName = q.id.replaceAll('.', 'z')
        if (variableName in r && r[variableName] != '') {
          let value = r[variableName].replaceAll('z', '.')

          values[q.id] = value
          if (value != '') {
            pp = q.pp;
            lp = q.lp
            variableValue = value
          }
        }
      }
      else if (q.type === 'QI') {
        variableName = q.id.replaceAll('.', 'z')
        if (variableName in r && r[variableName] != '') {
          let value = r[variableName].replaceAll('z', '.')
          values[q.id] = parseInt(value)
          if (value != '') {
            pp = q.pp;
            lp = q.lp
            variableValue = parseInt(value)
          }
        }
      }
      if (variableName) {
        vv[q.id] = variableValue
        vv[q.id + '_pp'] = pp
        vv[q.id + '_lp'] = lp
      }

    })
    items.push(vv)
  });
  return items;
}

const generateExcelRawDataRows = (variableValueItems, hierarchy, sequences) => {
  let rows = []

  // generatoe header
  // groups are used to get the correct order for sections/questions.
  const groups = []
  flattenHierarchy(groups, hierarchy)
  let headerRow = []
  groups.forEach(g => {
    sequences[g.sheet].forEach(q => {
      if (q.type != 'P') {
        headerRow.push(q.id)
        headerRow.push(q.id + '_PP')
        headerRow.push(q.id + '_LP')
      }

    })
  })
  rows.push(headerRow)

  // for each answer
  variableValueItems.forEach(vv => {
    let row = []
    // for each section 
    groups.forEach(g => {
      // for each question
      sequences[g.sheet].forEach(q => {
        if(q.type != 'P') {
          let value = vv[q.id] || ''
          let pp = vv[q.id + '_pp'] || ''
          let lp = vv[q.id + '_pp'] || ''
          row.push(value)
          row.push(pp)
          row.push(lp)
        }
      })

    })
    rows.push(row)
  })
  return rows

}

const generateExcelResultDataRows = (variableValueItems, configuration) => {
  let rows = []

  // header row
  let header = []
  configuration.classifications.forEach(c => {
    header.push(c.group + ' - PP') 
    header.push(c.group + ' - LP') 
    header.push(c.group + ' - P') 
    header.push(c.group + ' - aste') 
  })
  header.push('Taso')
  rows.push(header)
  const {classifications, questions, metadata, levelGenerator}  = configuration
  variableValueItems.forEach(vv => {
    const row = []   
    const orgType = vv[metadata[0].excludeVariable] || null
    const c = evaluateClassifications(classifications, questions, vv, metadata[0].excludeVariable)
    const level = calculateLevel(c, levelGenerator, orgType)
    c.forEach(_c => {
      row.push(_c.pp )
      row.push(_c.lp )
      row.push(_c.p )
      row.push(_c.degree )      
    })
    row.push(level)
    rows.push(row)
  })
  return rows
}

const handleLMCodeValues = (rows, q, qid, qIndex, LMLanguage, lindex, oldVersion) => {
  q.values.forEach(v => {
    let linkText = ''
    if (v.link && v.link != '') {
      linkText = '"<a target=""_blank"" href=""' + v.link + '"">' + v.text2[lindex] + '</a>"'
    }
    if (q.type === 'QM') {
      if(oldVersion) {
        rows.push([
                  'SQ',
                  '',
                  'SQz' + v.code,
                  '',
                  (linkText != '' ? linkText : '"' + v.text2[lindex] + '"'),
                  '',
                  LMLanguage,
                  '',
                  '',
                  '',
                  'N',
                  '',
                  '0'
                ])
      }
      else {
        rows.push([
                  qIndex,
                  '',
                  'SQ',
                  '',
                  'SQz' + v.code,
                  '',
                  (linkText != '' ? linkText : '"' + v.text2[lindex] + '"'),
                  '',
                  LMLanguage,
                  '',
                  '',
                  '',
                  'N',
                  '',
                  '0'
                ])        
      }

      qIndex = qIndex + 1
    }
    else {
      if(oldVersion) {
        rows.push([
                  'A',
                  '0',
                  'Lz' + v.code,
                  '',
                  (linkText != '' ? linkText : '"' + v.text2[lindex] + '"'),
                  '',
                  LMLanguage
                ])
      }
      else {
        rows.push([
                  qIndex,
                  '',
                  'A',
                  '0',
                  'Lz' + v.code,
                  '',
                  (linkText != '' ? linkText : '"' + v.text2[lindex] + '"'),
                  '',
                  LMLanguage
                ])        
      }

    }



  })
  return qIndex

}

const getLocalizedSurveyLabel = (labels, index)  => {
  if(labels.length > index) {
    if(labels[index] != '') {
      return labels[index]
    }
  }
  return 'empty'
}

const generateLimeSurveyRows = (hierarchy, sequences, langs, oldVersion) => {
  const groups = []
  const rows = []  
  // flatten hierarchy
  flattenHierarchy(groups, hierarchy)
  let gIndex = 1
  groups.forEach(g => {
    langs.forEach((LMLanguage, lindex) => {


      if(oldVersion) {
        rows.push([
                'G',
                'G' + gIndex,
                '"' +  getLocalizedSurveyLabel(g.label, lindex) + '"',
                '1',
                '',
                '',
                LMLanguage
              ])

      }
      else {
        rows.push([
                gIndex,
                '',
                'G',
                'G' + gIndex,
                '"' + getLocalizedSurveyLabel(g.label, lindex) + '"',
                '1',
                '',
                '',
                LMLanguage
              ])
      }


      gIndex = gIndex + 1
      if (!(g.sheet in sequences)) {
        throw Error('Missing sheet in configuration. Sections is referring to sheet "' + g.sheet + '" that is not part of the configuration.')
      }
      sequences[g.sheet].forEach(q => {
        //console.log(q)
        const type = q.type
        //console.log(q)
        let qid = ''
        if (q.id)
          qid = q.id.replaceAll('.', 'z')
        if (type == 'P') {
          if(oldVersion) {
            rows.push([
                        'Q',
                        'X',
                        qid,
                        '1',
                        '"' + getLocalizedSurveyLabel(q.text2, lindex) + '"',
                        '',
                        LMLanguage,
                        ,''                        
                        ,'N'
                        ,''
                        ,'1'
                  
                      ])
          }
          else {
            rows.push([
                        gIndex,
                        '',
                        'Q',
                        'X',
                        qid,
                        '1',
                        '"' + getLocalizedSurveyLabel(q.text2, lindex) + '"',
                        '',
                        LMLanguage
                        ,''                        
                        ,'N'
                        ,''
                        ,'1'
                      ])          
          }          
        }
        else if (type == 'QSO') { // with other option
          if(oldVersion) {
            rows.push([
                        'Q',
                        'L',
                        qid,
                        '1',
                        '"' + getLocalizedSurveyLabel(q.text2, lindex) + '"',
                        '',
                        LMLanguage,
                        '',
                        'N', // mandatory
                        'Y' // other option
                      ])
          }
          else {
            rows.push([
                        gIndex,
                        '',
                        'Q',
                        'L',
                        qid,
                        '1',
                        '"' + getLocalizedSurveyLabel(q.text2, lindex) + '"',
                        '',
                        LMLanguage,
                        '',
                        'N', // mandatory
                        'Y' // other option
                      ])
          }

          gIndex = gIndex + 1
          gIndex = handleLMCodeValues(rows, q, qid, gIndex, LMLanguage, lindex, oldVersion)
        }
        else if (type == 'QS') {
          if(oldVersion) {
            rows.push([
                        'Q',
                        'L',
                        qid,
                        '1',
                        '"' + getLocalizedSurveyLabel(q.text2, lindex) + '"',
                        '',
                        LMLanguage,
                        '',
                        'N' // mandatory
                      ])
          }
          else {
            rows.push([
                        gIndex,
                        '',
                        'Q',
                        'L',
                        qid,
                        '1',
                        '"' + getLocalizedSurveyLabel(q.text2, lindex) + '"',
                        '',
                        LMLanguage,
                        '',
                        'N' // mandatory
                      ])
          }

          gIndex = gIndex + 1
          gIndex = handleLMCodeValues(rows, q, qid, gIndex, LMLanguage, lindex, oldVersion)
        }
        else if (type == 'QM') {
          if(oldVersion) {
            rows.push([
                        'Q',
                        'M',
                        qid,
                        '1',
                        '"' + getLocalizedSurveyLabel(q.text2, lindex) + '"',
                        '',
                        LMLanguage
                      ])          
          }
          else {
            rows.push([
                        gIndex,
                        '',
                        'Q',
                        'M',
                        qid,
                        '1',
                        '"' + getLocalizedSurveyLabel(q.text2, lindex) + '"',
                        '',
                        LMLanguage
                      ])          
          }
          gIndex = gIndex + 1
          gIndex = handleLMCodeValues(rows, q, qid, gIndex, LMLanguage, lindex, oldVersion)
        }
        else if (type == 'QT') {
          if(oldVersion) {
            rows.push([
                        'Q',
                        'S',
                        qid,
                        '1',
                        '"' + getLocalizedSurveyLabel(q.text2, lindex) + '"',
                        '',
                        LMLanguage
                      ])
          }
          else {
            rows.push([
                        gIndex,
                        '',
                        'Q',
                        'S',
                        qid,
                        '1',
                        '"' + getLocalizedSurveyLabel(q.text2, lindex) + '"',
                        '',
                        LMLanguage
                      ])          
          }

        }
        else if (type == 'QI') {
          if(oldVersion) {
            rows.push([
                        'Q',
                        'N',
                        qid,
                        '1',
                        '"' + getLocalizedSurveyLabel(q.text2, lindex) + '"',
                        '',
                        LMLanguage
                      ])          
          }
          else {
            rows.push([
                        gIndex,
                        '',
                        'Q',
                        'N',
                        qid,
                        '1',
                        '"' + getLocalizedSurveyLabel(q.text2, lindex) + '"',
                        '',
                        LMLanguage
                      ])          
          }

        }

        gIndex = gIndex + 1
      })
    })
  })
  return rows

}
const parseLocalizedNames = (text, languages) => {
  const langs = ("" + text).trim().split('|')
  let text2 = []
  if(langs.length > 0 && languages != null ) {
    languages.forEach((l, lindex) => {
      if(langs.length > lindex) {
        text2.push(langs[lindex].trim())
      }
    })

  }
  else {    
    text2 = [text]        
  }  
  return text2
}

const parseExcel = async (wb) => {
  // create hierarchy using simulator sheet
  let docs = {}

  const m_sheet = wb.Sheets['Metadata']
  if (!m_sheet) {
    throw new Error("Metadata sheet not found!")
  }
  let m_data = XLSX.utils.sheet_to_json(m_sheet)
  // set default language 
  if('languages' in m_data[0]) {
    let langs = m_data[0].languages.split("|")
    if(langs.length > 0 ) {
      langs = langs.map(l => {return l.trim()})
      m_data[0].languages = langs
    }
    else {
      m_data[0].languages = ['fi']
    }
  }
  else {
    m_data[0].languages = ['fi']
}

  const sim = wb.Sheets['Sections']
  if (!sim) {
    // set error
    console.error("Sections sheet not found!")
    return null
  }

  let sim_data = XLSX.utils.sheet_to_json(sim)
  let parent_index = -1
  let parent_name = ''
  let hierarchy = []
  let dataSheets = []

  sim_data.forEach((s, index) => {
    if (s.Level == 1 && s.Name != parent_name) {
      let labels_source = s.Name
      const labels = parseLocalizedNames(labels_source, m_data[0].languages)
      let obj = { 'label': labels, 'sheet': s.Sheet, 'children': [] }

      parent_index = parent_index + 1
      parent_name = s.Name

      hierarchy.push(obj)
    }
    else if (s.Level == 2) {
      //console.log(hierarchy)   
      //console.log(parent_index)
      let labels_source = s.Name
      const labels = parseLocalizedNames(labels_source, m_data[0].languages)      
      let obj = { 'label': labels, 'sheet': s.Sheet, 'children': [] }
      hierarchy[parent_index]['children'].push(obj)

    }
    if (s.Sheet != '') {
      dataSheets.push(s.Sheet)
    }
  })
  //console.log(hierarchy)
  //console.log(dataSheets)

  let sequences = {}
  let questions = {}
  let dependantVariables = {}
  // each sheet is sequence (except Simulator)

  dataSheets.forEach((sheetName) => {
    if (sheetName == 'Simulator') {
      return
    }

    let sheetData = XLSX.utils.sheet_to_json(wb.Sheets[sheetName])
    let parentQuestion = null

    for (var i = 0; i < sheetData.length; i++) {
      let row = sheetData[i]
      if (!(sheetName in sequences)) {
        sequences[sheetName] = []
      }
      // first check the type 
      let type = row['Tyyppi']
      let text = row['Teksti']
      // text can contain multiple language versions on separate rows 
      
      const text2 = parseLocalizedNames(text, m_data[0].languages)

      let id = row['Tunniste']
      let pp = 0
      let lp = 0
      if ('pp' in row) {
        pp = row['pp']
      }
      if ('lp' in row) {
        lp = row['lp']
      }

      let obj = {}
      if (type == 'P') {
        parentQuestion = null
        sequences[sheetName].push(
          { id:id, type: type, text: text, text2: text2 }
        )
      }
      else if (type == 'QSO' || type == 'QS') {
        let q = { id: id, type: type, text: text, text2: text2, values: [] }
        sequences[sheetName].push(
          q
        )
        parentQuestion = sequences[sheetName].length - 1
        questions[id] = q
      }
      else if (type == 'QM') {
        let q = { id: id, type: type, text: text, text2: text2, values: [] }
        sequences[sheetName].push(
          q
        )
        parentQuestion = sequences[sheetName].length - 1
        questions[id] = q
      }
      else if (type == 'C') {
        let q = sequences[sheetName][parentQuestion]
        var cell_ref = XLSX.utils.encode_cell({ c: 2, r: row.__rowNum__ });
        var cell = wb.Sheets[sheetName][cell_ref]
        //console.log(cell)
        let link = null
        if ('l' in cell) {
          link = cell.l.Target
        }
        let generator = null
        let generator_param = null
        if ('select' in row && row['select'] != '') {
          let cc = 'let VALUE = args;' + row['select']
          generator = eval(new Function('...args', cc))
          generator_param = row['select_value']
          if (!(generator_param in dependantVariables)) {
            dependantVariables[generator_param] = new Set()
          }
          dependantVariables[generator_param].add(q.id)
        }

        let pp = 0
        let lp = 0
        if ('pp' in row) {
          pp = row['pp']
        }
        if ('lp' in row) {
          lp = row['lp']
        }
        if (q && q['type'].startsWith('Q')) {
          q.values.push(
            {
              type: type,
              code: id,
              text: text,
              text2: text2,
              link: link,
              pp: pp,
              lp: lp,
              generator: generator,
              generator_param: generator_param
            }
          )
        }
      }
      else if (type == 'QI') {
        parentQuestion = null
        let q = { id: id, type: type, text: text, text2: text2, pp: pp, lp: lp }
        sequences[sheetName].push(
          q
        )
        questions[id] = q
      }
      else if (type == 'QT') {
        parentQuestion = null
        let q = { id: id, type: type, text: text, text2: text2, pp: pp, lp: lp }
        sequences[sheetName].push(
          q
        )
        questions[id] = q

      }

    }

  })
  docs['sequences'] = sequences
  docs['questions'] = questions
  docs['hierarchy'] = hierarchy
  docs['metadata'] = m_data
  docs['dependantVariables'] = dependantVariables

  handleCustomClassification(docs, wb)
  console.log(docs)
  return docs
}

const handleCustomClassification = (docs, wb) => {

  const c = wb.Sheets['Asteet']
  const l = wb.Sheets['Luokat']
  if (c && l) {
    let c_data = XLSX.utils.sheet_to_json(c, { defval: "" })
    let data = []
    c_data.forEach(row => {
      let cc = 'let P = args[0];let PP=args[1];let LP=args[2];let MAX_P=args[3];MAX_PP = args[4]; let MAX_LP=args[5];' + row.Degree
      const generator = eval(new Function('...args', cc))

      data.push(
        {
          group: row['Group'],
          generatorStr: row.Degree,
          generator: generator,
          prefix: row.Prefix.split(','),
          RI: row['RI'],
          Uni: row['Uni'],
          AMK: row['AMK']

        }
      )
    })
    docs['classifications'] = data
    let l_data = XLSX.utils.sheet_to_json(l, { defval: "" })
    if (l_data.length > 0) {
      const levelGeneratorStr = l_data[0]['Level']
      docs['levelGenerator'] = levelGeneratorStr
    }


  }
  else {
    // TODO: throw and show error
  }
  return docs

}

const handlePoints = (values, value, points) => {
  if (typeof points === 'string' && points != '') {

    if (value.generator != null) {
      let gen_param = value.generator_param
      if (gen_param in values) {
        let param_value = values[gen_param]
        var lp_index = value.generator(param_value)
        var lp_values = value.lp.split(',')
        if (lp_values.length > lp_index) {
          return parseInt(lp_values[lp_index])
        }
        else {
          console.error('lp value index was larger that items available in the data. Returning zero')
          return 0
        }
      }
      else {
        console.warn('Select value was not set yet. Setting value to 0.')
        return 0
      }
    }
  }
  return points
}

const calculateMaxPoints = (questions, variableValues, prefixes, excludes) => {
  const maxValues = {}
  const missingGeneratorParams = []
  let totalMaxPP = 0
  let totalMaxLP = 0
  Object.entries(questions).forEach(([key, q]) => {
    if (includedInGroup(prefixes, key) && excludes.indexOf(key) < 0) {
      //console.log(key)
      let maxPP = -1
      let maxLP = -1
      //console.log(q)
      const id = q['id']
      // TODO: check for id 
      const type = q['type']
      if (type === 'QI' || type === 'QT') {
        maxPP = q['pp']
        maxLP = q['lp']
      }
      else if(type === 'QS' || type === 'QM') {
        //console.log(codeListValues)
        const values = q['values'].map(a => ({ ...a }));

        // update values based on generated values - check for missing generator params

        values.forEach(v => {
          if (v.generator_param != null) {

            if (!(v.generator_param in variableValues) || variableValues[v.generator_param] === '') {
              // required param is not set yet 
              if (missingGeneratorParams.indexOf(v.generator_param) < 0) {
                missingGeneratorParams.push(v.generator_param)
              }

            }
            else {
              v['pp'] = handlePoints(variableValues, v, v.pp)
              v['lp'] = handlePoints(variableValues, v, v.lp)
            }
          }
        })
        if (missingGeneratorParams.length > 0) {
          throw new MissingGeneratorParameterError(missingGeneratorParams)
        }
        if (type == 'QS') {
          // find max pp and max lp 
          maxPP = values.reduce((a, b) => a.pp > b.pp ? a : b).pp
          maxLP = values.reduce((a, b) => a.lp > b.lp ? a : b).lp

        }
        if (type == 'QM') {
          // find max pp and max lp 
          maxPP = values.reduce((sum, cur) => sum + cur.pp, 0)
          maxLP = values.reduce((sum, cur) => sum + cur.lp, 0)

        }
      }
      if(maxPP > 0)
        totalMaxPP = totalMaxPP + maxPP
      if(maxLP > 0)
        totalMaxLP = totalMaxLP + maxLP

      maxValues[id] = { 'pp': maxPP, 'lp': maxLP, 'max': maxPP + maxLP }
    }
  })
  //console.log(maxValues)
//  if(totalMaxPP > 0)
    maxValues['MAX_PP'] = totalMaxPP
//  if(totalMaxLP > 0)
    maxValues['MAX_LP'] = totalMaxLP
//  if(totalMaxPP + totalMaxLP > 0)
    maxValues['MAX_P'] = totalMaxPP + totalMaxLP

  return maxValues
}

const includedInGroup = (prefixes, key) => {
  let r = false
  prefixes.forEach(p => {
    if (key.startsWith(p)) {
      r = true
    }
  })
  return r
}

const calculatePointsForGroup = (questions, variableValues, prefix, excludes) => {
  let pp = 0
  let lp = 0
  Object.entries(questions).forEach(([key, q]) => {
    if (includedInGroup(prefix, key)) {
      if (key in variableValues && excludes.indexOf(key) < 0) {
        pp = pp + variableValues[key + '_pp']
        lp = lp + variableValues[key + '_lp']
      }
    }
  })
  return { 'P': pp + lp, 'PP': pp, 'LP': lp }
}


const calculateLevel = (degrees, levelGenerator, orgType) => {
  let cc = 'let AVG_DEGREE = args[0];let MIN_DEGREE_1_COUNT=args[1];let MIN_DEGREE_2_COUNT=args[2];let MIN_DEGREE_3_COUNT=args[3];MIN_DEGREE_4_COUNT = args[4]; let MIN_DEGREE_5_COUNT=args[5];' + levelGenerator
  const generator = eval(new Function('...args', cc))

  let c1 = 0
  let c2 = 0
  let c3 = 0
  let c4 = 0
  let c5 = 0
  let s = 0
  
  if(orgType === 3) {
    // this is hacky, because it assumes that groups are in particular order
    degrees = [...degrees].slice(0,3)
  }
  
  degrees.forEach(d => {
    s = s + d.degree
    //console.log(d)
    if (d.degree === 1) {
      c1 = c1 + 1
    }
    else if (d.degree === 2) {
      c1 = c1 + 1
      c2 = c2 + 1
    }
    else if (d.degree === 3) {

      c1 = c1 + 1
      c2 = c2 + 1
      c3 = c3 + 1
    }
    else if (d.degree === 4) {

      c1 = c1 + 1
      c2 = c2 + 1
      c3 = c3 + 1
      c4 = c4 + 1
    }
    else if (d.degree === 5) {

      c1 = c1 + 1
      c2 = c2 + 1
      c3 = c3 + 1
      c4 = c4 + 1
      
      c5 = c5 + 1
    }
  })

  
  const AVG_DEGREE = s / degrees.length
  const MIN_DEGREE_1_COUNT = c1
  const MIN_DEGREE_2_COUNT = c2
  const MIN_DEGREE_3_COUNT = c3
  const MIN_DEGREE_4_COUNT = c4
  const MIN_DEGREE_5_COUNT = c5

  const level = generator(AVG_DEGREE, MIN_DEGREE_1_COUNT, MIN_DEGREE_2_COUNT, MIN_DEGREE_3_COUNT, MIN_DEGREE_4_COUNT, MIN_DEGREE_5_COUNT)
  return level
}

const evaluateClassifications = (classifications, questions, variableValues, excludeVariable) => {
  let cdata = []
  classifications.forEach(c => {
    let group = c.group
    let qprefix = c.prefix

    let excludes = ''
    if (excludeVariable in variableValues && variableValues[excludeVariable] != null && variableValues[excludeVariable] != '') {
      const orgValue = variableValues[excludeVariable]
      if (orgValue === 1) {
        excludes = c.Uni
      }
      else if (orgValue === 2) {
        excludes = c.AMK
      }
      else if (orgValue === 3) {
        excludes = c.RI
      }
    }
    else {
      throw new MissingGeneratorParameterError([excludeVariable])
    }

    let points = calculatePointsForGroup(questions, variableValues, qprefix, excludes)
    let maxPoints = calculateMaxPoints(questions, variableValues, qprefix, excludes)
    const degree = c.generator(points['P'], points['PP'], points['LP'], maxPoints['MAX_P'], maxPoints['MAX_PP'], maxPoints['MAX_LP'])
    cdata.push(
      {
        group: group,
        degree: degree,
        p: points['P'],
        pp: points['PP'],
        lp: points['LP'],
        max_p: maxPoints['MAX_P'],
        max_pp: maxPoints['MAX_PP'],
        max_lp: maxPoints['MAX_LP']

      }
    )


  })
  return cdata
}

export { 
  parseSurveyResults, 
  getPointMatrixSum, 
  calculatePointMatrix, 
  generateExcelRawDataRows, 
  generateExcelResultDataRows,
  generateLimeSurveyRows, 
  parseExcel, 
  MissingGeneratorParameterError, 
  handlePoints, 
  evaluateClassifications, 
  calculateLevel 
}