import { Box } from '@mui/system';
import * as React from 'react';
import SurveySection from './SurveySection';
import { createRef } from 'react'

import { on, off } from "../events";
import { Tab, Tabs, Typography } from '@mui/material';
import TabPanel from './TabPanel';

import {UpdatedConfigsContext} from '../UpdatedConfigsContext'


function Survey(props) {
    const {readOnly, hierarchy, sequences, variableValues, onInput, ...other } = props;
    const {selectedLang, setSelectedLang} = React.useContext(UpdatedConfigsContext)

    const [levelValue, setLevelValue] = React.useState(0)
    const [subLevelValue, setSubLevelValue] = React.useState(0)

    
    const lineRefs = React.useRef([])
    lineRefs.current = hierarchy.map((_, i) => 
      lineRefs.current[i] ?? createRef()
    )
  
  
    
    const handleLevelChange = (event, newValue) => {
      setSubLevelValue(0)
      setLevelValue(newValue);
      if(readOnly) {
        lineRefs.current[newValue].current.scrollIntoView()
        window.scrollTo(0,0)
    
      }
    };
  
    const handleSubLevelChange = (event, newValue) => {
      setSubLevelValue(newValue);
      if(readOnly) {      
        document.getElementById('title' + levelValue + '-' + newValue).scrollIntoView()
        window.scrollTo(0,0)
    
      }
    };
  
    const reset = () => {
      setLevelValue(0)
      setSubLevelValue(0)
    }
    React.useEffect(() => {
      on("resetSimulator", reset);
  
      return () => {
        off("resetSimulator", reset);
      }
    }, [reset]);
  
  
    const renderQuestions = (sheet) => {
      
      let s = sequences[sheet]
      if(!s)
        return
      return (
        <Box sx={{ textAlign:'left', maxHeight: window.innerHeight-128, overflow: 'auto' }}>
          <Box>
            
            {
              s.map( (q, index) => {
                return <SurveySection key={index} readOnly={readOnly} q={q} qindex={index} variableValues={variableValues} onInput={onInput} />
              })
            }
          </Box>
        </Box>
      )    
    }
  
  
  
    const renderSheet = (sheet) => {
      let s = sequences[sheet]
      let questions = 
        s.map( (q, index) => {
          return <SurveySection key={index} readOnly={readOnly} q={q} qindex={index} variableValues={variableValues} onInput={onInput} />
        })
      
      return questions
    }
  
    
    return (
    <>
      <Tabs
        orientation="horizontal"
        value={levelValue}
        onChange={handleLevelChange}
        sx={{ borderRight: 1, borderColor: 'divider', m: '10px' }}
      >
        {
          props.hierarchy.map((h, h_index) => {
            return (
              <Tab key={h_index} label={h.label[selectedLang]} />
            )
          })
        }
      </Tabs>
      {              
        !readOnly && hierarchy.map( (h, h_index) => {
          if(h['children'].length === 0)                 
            return (
              <TabPanel key={h_index} value={levelValue} index={h_index} >
              {renderQuestions(h['sheet'])}
            </TabPanel>
            )
          else if(h['children'].length > 0)
            return(
              <TabPanel key={h_index} value={levelValue} index={h_index} >
                <Tabs
                  orientation="horizontal"
                  value={subLevelValue}
                  onChange={handleSubLevelChange}
                  sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                  {
                    h['children'].map( (hh, hh_index) => {
                      return (
                        <Tab key={hh_index} label={hh.label[selectedLang]} />
                      )
                    })
                  }
                </Tabs>
                {
                    h['children'].map( (hh, hh_index) => {
                      return (
                        <TabPanel key={hh_index} value={subLevelValue} index={hh_index}>
                          {renderQuestions(hh['sheet'])}
                        </TabPanel>
                      )
                    })                        
                  }                    
                
              </TabPanel>
  
          )
  
        })
      }
      <Box>
      {
        readOnly && hierarchy.map( (h, h_index) => {
          if(h['children'].length > 0)
            return(
              <TabPanel key={h_index} value={levelValue} index={h_index} >
                <Tabs
                  orientation="horizontal"
                  value={subLevelValue}
                  onChange={handleSubLevelChange}
                  sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                  {
                    h['children'].map( (hh, hh_index) => {
                      return (
                        <Tab key={hh_index} label={hh.label[selectedLang]} />
                      )
                    })
                  }
                </Tabs> 
              </TabPanel>
            )       
  
        })
      }
      </Box>
      {
        readOnly && 
        
        <TabPanel value={0} index={0}>
          <Box sx={{ textAlign:'left', maxHeight: window.innerHeight-128, overflow: 'auto' }}>
          {
          hierarchy.map( (h, h_index) => {
            return (
              <Box key={h_index} sx={{marginTop:2}}>
              <Typography variant='h4' ref={lineRefs.current[h_index]}>{h.label[selectedLang]}</Typography>
              {
                h.children.length == 0 && 
                renderSheet(h['sheet'])
              }
              {
                h.children.length > 0 && h.children.map( (hh, hh_index) => {
                  return (
                    <Box key={h_index + '-' + hh_index}>
                      <Typography variant='h5' id={'title' + h_index + '-' + hh_index}>{hh.label[selectedLang]}</Typography>
                      {renderSheet(hh['sheet'])}
                    </Box>
                  )
                  
                })
  
              }
            </Box>
  
            )
  
          })
          }
          </Box>
        </TabPanel>
      }
    </>
    )    
}
export default Survey